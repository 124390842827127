import React, { Component } from 'reactn'

export default class Devices extends Component {
  render () {
    return (
      <div>
        {this.props.location.branch}
      </div>
    )
  }
}
