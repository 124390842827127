import React, { Component } from 'reactn'
import {
  Home, Login, ControlloAccessi, NetworkTester,
  Videosorveglianza, Devices, Settings
} from './Pages'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { restoreState } from './Controllers/GlobalState'

export default class App extends Component {
  async componentDidMount () {
    await restoreState()
  }

  render () {
    return (
      <Router>
        <Route path='/' exact component={Login} />
        <Route path='/home' exact component={Home} />
        <Route path='/cards' component={ControlloAccessi} />
        <Route path='/network' component={NetworkTester} />
        <Route path='/camera' component={Videosorveglianza} />
        <Route path='/devices' component={Devices} />
        <Route path='/settings' component={Settings} />
      </Router>
    )
  }
}
