import React, { Component } from 'reactn'
import { Card, Button, Form } from 'react-bootstrap'
import ClientApi from '../Controllers/ClientApi'
import swal from 'sweetalert'
import { setStorageKey, persistState } from '../Controllers/GlobalState'

export default class extends Component {
  constructor (props) {
    super(props)
    this.state = {
      user: '',
      psw: ''
    }
  }

  async doLogin () {
    const { user, psw } = this.state
    if (user === '' || psw === '') {
      swal({
        title: 'Errore',
        text: 'Completare tutti i campi, poi premere login',
        icon: 'error',
        buttons: false,
        timer: 2000
      })
    } else {
      const ret = await ClientApi.login('/authentication', {
        email: user,
        password: psw,
        strategy: 'local'
      })
      if (ret.ok) {
        await this.setGlobal({ token: ret.token }, persistState)
        const res = await ClientApi.get('/users?email=' + user)
        if (res.ok && res.data) {
          const companyID = res.data[0]._id
          this.setGlobal({ email: user, companyID }, persistState)
          this.props.history.push('/home')
        }
      } else {
        swal({
          title: 'Errore',
          text: 'Le credenziali di accesso non sono corrette.',
          icon: 'error',
          buttons: false,
          timer: 2000
        })
      }
    }
  }

  render () {
    const { cardSection, cardBody, logoStyle, full } = style
    const logoframe = require('../Assets/logo.png')
    return (
      <div style={full}>
        <div style={cardBody}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <img src={logoframe} alt='' style={{ ...logoStyle, backgroundColor: 'white', borderRadius: 10, padding: 40, height: 190 }} />
          </div>
          <Card style={cardSection}>
            <Form style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
              <Form.Group controlId='formBasicEmail'>
                <Form.Control type='text' placeholder='Username' onChange={(e) => { this.setState({ user: e.target.value }) }} />
              </Form.Group>
              <Form.Group controlId='formBasicPassword'>
                <Form.Control type='password' placeholder='Password' onChange={(e) => { this.setState({ psw: e.target.value }) }} />
              </Form.Group>
              <Button variant='primary' style={{ width: 100 }} onClick={() => this.doLogin()}>Login</Button>
            </Form>
          </Card>
        </div>
      </div>
    )
  }
}

const style = {
  full: {
    minWidth: '100vw',
    minHeight: '100vh',
    backgroundImage: `url(${require('../Assets/Icons/back.jpeg')})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPositionY: 'center'
  },
  cardBody: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: 'none'
  },
  cardSection: {
    width: 300,
    height: 200,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'auto auto',
    marginTop: 40,
    borderRadius: 10
  },
  baseText: {
    marginBottom: 50
  },
  logoStyle: {
    width: 500,
    margin: 50
  }
}
