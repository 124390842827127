import React, { Component } from 'reactn'
import Detail from './Detail'
import { NewCard } from './Modals'
import { Card, Button, Modal, Breadcrumb, InputGroup, Form } from 'react-bootstrap'
import BootstrapTable from 'react-bootstrap-table-next'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import filterFactory from 'react-bootstrap-table2-filter'
import paginationFactory from 'react-bootstrap-table2-paginator'
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css'
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css'
import ClientApi from '../../Controllers/ClientApi'
import io from 'socket.io-client'
import feathers from '@feathersjs/feathers'
import socketio from '@feathersjs/socketio-client'
import swal from 'sweetalert'
import { CSVLink } from 'react-csv'
import moment from 'moment'

const renderCell = (cell) => { return <p style={styles.cell}>{cell}</p> }
// const renderEnabled = (cell) => {
//   return (
//     <Form.Check
//       style={{ marginTop: 5 }}
//       checked={cell === 1}
//       disabled
//     />
//   )
// }
const renderTitle = (cell) => { return <p style={styles.cell}><b>{cell}</b></p> }

export default class NetworkTester extends Component {
  constructor (props) {
    super(props)
    this.state = {
      data: [],
      showModal: false
    }
  }

  async refreshTableData () {
    const res = await ClientApi.get('/branch')
    if (res && res.data !== undefined) {
      this.setState({ data: res.data })
    } else {
      swal({
        title: 'Errore',
        text: 'Nessuna connessione con il server, contattare l\'assistenza',
        icon: 'error',
        closeOnClickOutside: false,
        closeOnEsc: false,
        buttons: false,
        timer: 3000
      })
      // setTimeout(() => { this.props.history.push('/') }, 3000)
    }
  }

  async componentDidMount () {
    await this.refreshTableData()

    const client = feathers()
    client.configure(socketio(io(ClientApi.getUrl())))
    client.service('network').on('created', async response => {
      response._id !== undefined && await this.refreshTableData()
    })

    client.service('network').on('removed', async response => {
      response._id !== undefined && await this.refreshTableData()
    })

    client.service('network').on('patched', async response => {
      response._id !== undefined && await this.refreshTableData()
    })
  }

  render () {
    const {
      page, mainkit, toolkit, lateral, table,
      internalLateral, funcbtn, lateralSmall,
      main, container, iconStyle
    } = styles
    const network = require('../../Assets/Icons/network.png')
    const { SearchBar, ClearSearchButton } = Search
    const defaultSorted = [{ dataField: 'branch', order: 'asc' }]
    const { data } = this.state

    const rowEvents = {
      onClick: (e, row) => {
        this.props.history.push({ pathname: '/devices', branch: row.branch })
      }
    }

    const rowBackground = (row, rowIndex) => {
      const style = {}
      rowIndex % 2 === 0 ? style.backgroundColor = '#cfdede' : style.backgroundColor = '#f9f9f9'
      return style
    }

    const options = {
      paginationSize: 4,
      pageStartIndex: 0,
      alwaysShowAllBtns: true,
      withFirstAndLast: true,
      firstPageText: 'Inzio',
      prePageText: 'Indietro',
      nextPageText: 'Avanti',
      lastPageText: 'Fine',
      showTotal: true,
      paginationTotalRenderer: (from, to, size) => { return (<i> Records da <b>{from}</b> a <b>{to} .</b> Risultati totali: <b>{size}</b></i>) },
      sizePerPageList: [{ text: '12', value: 12 }]
    }

    return (
      <div style={page}>
        <div style={lateral}>
          <Card
            style={{
              ...main,
              backgroundColor: 'rgba(234, 152, 78, 1.0)',
              WebkitBoxShadow: '7px 7px 21px -1px rgba(234, 152, 78, 1.0)',
              MozBoxShadow: '7px 7px 21px -1px rgba(234, 152, 78, 1.0)',
              boxShadow: '7px 7px 21px -1px rgba(234, 152, 78, 1.0)'
            }}
            onClick={() => this.props.history.push('/')}
          >
            <Card style={container}>
              <img src={network} alt='void' style={iconStyle} />
              <p style={{ paddingTop: 10 }}>Network Tester</p>
            </Card>
          </Card>
          <Card style={{ ...lateralSmall, marginTop: 20 }}>
            <Card.Header style={{ color: 'rgb(106, 117, 126)' }}><b>Funzioni</b></Card.Header>
            <div style={internalLateral}>
              <Button variant='outline-primary' style={funcbtn} onClick={() => this.setState({ showModal: true })}>Nuova card</Button>
            </div>
            <Modal show={this.state.showModal} onHide={() => this.setState({ showModal: false })}>
              <Modal.Header closeButton>
                <Modal.Title>Inserire i dati del nuovo device da monitorare</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <NewCard close={() => this.setState({ showModal: false })} />
              </Modal.Body>
            </Modal>
          </Card>
          <Card style={{ ...lateralSmall, marginTop: 20 }}>
            <Card.Header style={{ color: 'rgb(106, 117, 126)' }}><b>Strumenti</b></Card.Header>
            <div style={internalLateral}>
              <CSVLink
                style={funcbtn}
                filename={'Cards-' + moment().unix() + '.csv'} className='btn btn-outline-danger'
                data={this.state.data.map((el, i) => {
                  return {
                    ID: i,
                    Filiale: el.branch,
                    Indirizzo: el.address,
                    Telefono: el.phone,
                    EMail: el.email
                  }
                })}
              >Download CSV
              </CSVLink>
            </div>
          </Card>
        </div>
        <div style={table}>
          <ToolkitProvider
            keyField='code' data={data} columns={fields} loading striped hover search
            exportCSV={{
              fileName: 'Filiali-' + moment().unix() + '.csv',
              onlyExportFiltered: false
            }}
          >
            {props =>
              <div style={{ padding: 10 }}>
                <Card style={mainkit}>
                  <div style={toolkit}>
                    <Breadcrumb style={{ minWidth: '210px', margin: 10, height: 40 }}>
                      <Breadcrumb.Item href='#' onClick={() => this.props.history.push('/home')}>Home</Breadcrumb.Item>
                      <Breadcrumb.Item active>Network Tester</Breadcrumb.Item>
                    </Breadcrumb>
                    <Button variant='success' style={{ minWidth: 120, height: 40, margin: 10 }} onClick={() => this.props.history.push('/home')}>Indietro</Button>
                    <InputGroup style={{ margin: 10, height: 40, display: 'flex', flexDirection: 'row', alignItems: 'stretch' }}>
                      <InputGroup.Prepend style={{ width: '100px' }}>
                        <ClearSearchButton {...props.searchProps} text='Reset' className='reset-btn' />
                      </InputGroup.Prepend>
                      <SearchBar
                        {...props.searchProps} className='search-item'
                        placeholder='Inizia a digitare per filtrate...'
                        delay={50} style={{ height: 40, fontStyle: 'italic' }}
                      />
                    </InputGroup>
                  </div>
                  <BootstrapTable
                    {...props.baseProps}
                    noDataIndication='Nessun record trovato'
                    filter={filterFactory()}
                    pagination={paginationFactory(options)}
                    rowEvents={rowEvents}
                    tabIndexCell
                    bootstrap4
                    defaultSorted={defaultSorted}
                    rowStyle={rowBackground}
                  />
                </Card>
              </div>}
          </ToolkitProvider>
        </div>
      </div>
    )
  }
}

const styles = {
  page: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    flexDirection: 'row',
    background: 'rgba(30,30,30,0.9)'
  },
  lateral: {
    width: '14%',
    margin: 10
  },
  lateralSmall: {
    margin: 5,
    borderRadius: 10,
    WebkitBoxShadow: '7px 7px 21px -1px rgba(255, 255, 255, 0.5)',
    MozBoxShadow: '7px 7px 21px -1px rgba(255, 255, 255, 0.5)',
    boxShadow: '7px 7px 21px -1px rgba(255, 255, 255, 0.5)'
  },
  internalLateral: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'stretch'
  },
  funcbtn: {
    margin: 8
  },
  table: {
    width: '84%'
  },
  column: {
    background: 'rgb(0, 118, 197)',
    color: 'white',
    fontSize: 16,
    textTransform: 'uppercase',
    height: 10
  },
  row: {
    color: 'white',
    height: 10
  },
  cell: {
    fontSize: 15,
    wordWrap: 'break-word'
  },
  mainkit: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 10,
    WebkitBoxShadow: '7px 7px 21px -1px rgba(255, 255, 255, 0.5)',
    MozBoxShadow: '7px 7px 21px -1px rgba(255, 255, 255, 0.5)',
    boxShadow: '7px 7px 21px -1px rgba(255, 255, 255, 0.5)',
    padding: 10
  },
  toolkit: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 20
  },
  exportBtn: {
    width: 100,
    height: 40,
    backgroundColor: 'red',
    color: 'white',
    marginRight: 10,
    marginTop: 5
  },
  smallButton: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  main: {
    maxWidth: '100%',
    borderRadius: 10,
    margin: 5,
    marginBottom: 20
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    padding: 5,
    margin: 5,
    background: 'rgba(30,30,30,0.7)',
    color: 'white'
  },
  iconStyle: {
    width: '70%',
    marginTop: 10
  }
}

const fields = [
  {
    dataField: 'branch',
    text: 'Filiale',
    headerStyle: { ...styles.column, width: '35%' },
    sort: true,
    formatter: renderTitle,
    align: 'left'
  },
  {
    dataField: 'address',
    text: 'Indirizzo',
    headerStyle: { ...styles.column, width: '20%' },
    sort: true,
    formatter: renderCell,
    align: 'left'
  },
  {
    dataField: 'phone',
    text: 'Telefono',
    headerStyle: { ...styles.column, width: '12%' },
    sort: true,
    formatter: renderCell,
    align: 'left'
  },
  {
    dataField: 'mail',
    text: 'E-Mail',
    headerStyle: { ...styles.column, width: '12%' },
    sort: true,
    formatter: renderCell,
    align: 'left'
  }
  // {
  //   dataField: 'enabled',
  //   text: 'Abilitato',
  //   headerStyle: { ...styles.column, width: '10%' },
  //   sort: true,
  //   formatter: renderEnabled,
  //   align: 'center'
  // }
]
