import { setGlobal, resetGlobal } from 'reactn'
import BrowserStorage from './BrowserStorage'

const STORAGE_KEY = 'TECNEL'
const DEFAULT_STATE = {
  token: '',
  theme: 1
}

// export const setStorageKey = async id => {
//   STORAGE_KEY = 'TECNEL' + id
// }

export const restoreState = async () => {
  const local = await BrowserStorage.get(STORAGE_KEY)
  await setGlobal({
    // ...DEFAULT_STATE,
    ...local
  })
}

export const persistState = async state => {
  await BrowserStorage.set(STORAGE_KEY, state)
}

export const navigate = async currentPage => {
  await setGlobal({ currentPage }, persistState)
}

export const clearState = async () => {
  await resetGlobal()
  await BrowserStorage.clear()
  await setGlobal(DEFAULT_STATE)
}
