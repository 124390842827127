import React, { Component } from 'reactn'
import { Modal } from 'react-bootstrap'
import CamCard from './CamCard'

export default class Telecamera extends Component {
  constructor (props) {
    super(props)
    this.state = {
      modalActive: false
    }
  }

  async start () {
    const { url, id } = this.props
    try {
      const player = window.startStreaming(url, id)
      this.setState({ player })
    } catch (error) {
      console.log(error)
    }
  }

  stop () {
    if (this.state.player) {
      window.stopStreaming()
      this.setState({ player: null })
    }
  }

  render () {
    const { modalActive } = this.state
    const { icona } = styles
    const on = require('../../Assets/Icons/camera.png')

    return (
      <div>
        <img
          src={on}
          alt='void'
          onClick={() => { this.setState({ modalActive: !this.state.modalActive }) }}
          style={icona}
        />
        <Modal
          size='lg'
          style={{ display: 'flex', justifyContent: 'center', borderRadius: 10 }}
          centered
          show={modalActive}
          animation={false}
          onShow={() => this.start()}
          onHide={() => {
            this.setState({ modalActive: !this.state.modalActive })
            this.stop()
          }}
        >
          <Modal.Body style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', borderRadius: 10 }}>
            <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', fontSize: 30 }}>
              <CamCard idcam={this.props.id} description={this.props.description} />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}

const styles = {
  icona: {
    width: '10%'
  }
}
