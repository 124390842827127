import React, { Component } from 'reactn'

export default class CamCard extends Component {
  constructor (props) {
    super(props)
    this.state = {
      width: 760,
      height: 480,
      width7: 760,
      height7: 480
    }
  }

  render () {
    const { canvas, main, desc, detail } = styles
    const { idcam, description, container } = this.props
    const { width, height, width7, height7 } = this.state

    return (
      <div style={main}>
        <div style={{ ...canvas, ...container }}>
          {window.innerWidth < 1000 ? <canvas id={idcam} style={{ width7, height7 }} />
            : <canvas id={idcam} style={{ width, height }} />}
          <div style={detail}>
            <p style={desc}>{description}</p>
          </div>
        </div>
      </div>
    )
  }
}

const styles = {
  canvas: {
    overflow: 'hidden',
    position: 'relative',
    padding: 5,
    margin: 10,
    borderRadius: 20
  },
  detail: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center'
  },
  desc: {
    margin: 'auto',
    fontWeight: 'bold'
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    border: 'solid red 0px',
    WebkitBoxShadow: '7px 7px 21px -1px rgba(112,112,112,0.86)',
    MozBoxShadow: '7px 7px 21px -1px rgba(112,112,112,0.86)',
    boxShadow: '7px 7px 21px -1px rgba(112,112,112,0.86)',
    margin: 5
  }
}
