import React, { Component } from 'reactn'
import { Card, InputGroup, FormControl, Button, Form } from 'react-bootstrap'
import ClientApi from '../../../Controllers/ClientApi'
import swal from 'sweetalert'

class NewCard extends Component {
  constructor (props) {
    super(props)
    this.state = {
      access: {
        code: '',
        name: '',
        type: 'card',
        progressive: 0,
        room: 0,
        enabled: 0,
        company: this.props.companyID
      }
    }
  }

  async save () {
    const { access: { code, name } } = this.state // , type, progressive, room, enabled
    if (code !== '' && name !== '') {
      const res = await ClientApi.post('/access', this.state.access)
      res
        ? swal({
          title: 'Successo',
          text: 'La nuova card e\' stata registrata correttamente.',
          icon: 'success',
          buttons: false,
          timer: 2000
        }).then(() => { this.props.close() })
        : swal({
          title: 'Errore',
          text: 'Impossibile registrare la nuova card, si prega di contattare l\'assistenza.',
          icon: 'error',
          buttons: false,
          timer: 2000
        })
    } else {
      swal({
        title: 'Attenzione',
        text: 'I campi \'Codice\' e \'Nome / Ragione Sociale\' sono obbligatori. ',
        icon: 'warning',
        buttons: false,
        timer: 2000
      })
    }
  }

  render () {
    const { gruppo, label, testo, button } = styles
    const { code, name, type, progressive, room, enabled } = this.state.access

    return (
      <div style={{ margin: 10 }}>
        <Card.Body>
          <InputGroup style={gruppo}>
            <InputGroup.Prepend style={label}>
              <InputGroup.Text style={testo}>Codice</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              value={code}
              onChange={(e) => this.setState({ access: { ...this.state.access, code: e.target.value } })}
            />
          </InputGroup>
          <div style={{ ...gruppo, display: 'flex', width: '100%', flexDirection: 'column' }}>
            <InputGroup.Prepend style={label}>
              <InputGroup.Text style={{ ...testo, width: '100%' }}>Nome Cognome / Rag. Sociale</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              value={name}
              onChange={(e) => this.setState({ access: { ...this.state.access, name: e.target.value } })}
            />
          </div>
          <InputGroup style={gruppo}>
            <InputGroup.Prepend style={label}>
              <InputGroup.Text style={testo}>Tipo</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              value={type}
              disabled
            />
          </InputGroup>
          <InputGroup style={gruppo}>
            <InputGroup.Prepend style={label}>
              <InputGroup.Text style={testo}>Progressivo</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              value={progressive}
              onChange={(e) => this.setState({ access: { ...this.state.access, progressive: parseInt(e.target.value) } })}
            />
          </InputGroup>
          <InputGroup style={gruppo}>
            <InputGroup.Prepend style={label}>
              <InputGroup.Text style={testo}>Camera</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              value={room}
              onChange={(e) => this.setState({ access: { ...this.state.access, room: parseInt(e.target.value) } })}
            />
          </InputGroup>
          <InputGroup style={{ ...gruppo, width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
            <Form.Check
              id='enabled-switch'
              label='Abilita accesso'
              onChange={(e) => this.setState({ access: { ...this.state.access, enabled: enabled ? 0 : 1 } })}
            />
          </InputGroup>
          <InputGroup style={button}>
            <Button variant='outline-success' size='lg' onClick={() => this.save()}>Salva</Button>
          </InputGroup>
        </Card.Body>
      </div>
    )
  }
}

const styles = {
  gruppo: {
    marginBottom: 15
  },
  button: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  testo: {
    width: 90,
    justifyContent: 'center'
  }
}

export default NewCard
