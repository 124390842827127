import axios from 'axios'
import BrowserStorage from './BrowserStorage'

const ClientApi = {}
const STORAGE_KEY = 'TECNEL'
const local = BrowserStorage.get(STORAGE_KEY)

// const SERVER = 'http://chpl.it:3232'
const SERVER = 'http://localhost:3232'
ClientApi.getUrl = () => SERVER

const getClient = () => axios.create({
  baseURL: SERVER,
  timeout: 6000,
  headers: {
    'Content-Type': 'application/json',
    Authorization: local.token || ''
  }
})
// Token API controller@hotel-quadrif...
// eyJhbGciOiJIUzI1NiIsInR5cCI6ImFjY2VzcyJ9.eyJ1c2VySWQiOiI1ZGFkNWViODQzMDc1NDFmZTU4ODZiN2MiLCJpYXQiOjE1NzE2NTI1MzAsImV4cCI6MTYwMzIxMDEzMCwiYXVkIjoiaHR0cHM6Ly9mcmFtZXN5c3RlbS5pdCIsImlzcyI6ImZlYXRoZXJzIiwic3ViIjoiYW5vbnltb3VzIiwianRpIjoiZjgwM2UzYjAtZjgyMC00YzM4LTk0YzgtNzlhMzcxN2NkNTczIn0.xC4oNMH6sjpvczkxUG1OWVKcD5dJYSsVHrMcEl9fJN0

const client = getClient()

// ClientApi.setStorageKey = async id => {
//   STORAGE_KEY = 'TECNEL' + id
// }

// ClientApi.setJWT = jwt => {
//   accessToken = jwt
//   client = getClient()
// }

ClientApi.wait = ms => new Promise(resolve => setTimeout(resolve, ms))

ClientApi.get = async (url) => {
  try {
    const response = await client.get(url)
    response.ok = response.statusText === 'OK'
    return response
  } catch (err) {
    return { ok: false, error: err }
  }
}

ClientApi.patch = async (url, id, update) => {
  try {
    const response = await client.patch(`${url}/${id}`, update)
    response.ok = response.statusText === 'OK'
    return response
  } catch (err) {
    return { ok: false, error: err }
  }
}

ClientApi.delete = async (url, id) => {
  try {
    const response = await client.delete(`${url}/${id}`)
    response.ok = response.statusText === 'OK'
    return response
  } catch (err) {
    return { ok: false, error: err }
  }
}

ClientApi.post = async (url, data) => {
  try {
    const response = await client.post(url, data)
    response.ok = response.statusText === 'Created'
    if (response.ok) {
      return true
    }
    return false
  } catch (err) {
    return { ok: false, error: err }
  }
}

ClientApi.login = async (url, data) => {
  try {
    const response = await client.post(url, data)
    return response.data && response.data.accessToken ? { ok: true, token: response.data.accessToken } : { ok: false }
  } catch (err) {
    return { ok: false }
  }
}

export default ClientApi
