import React, { Component } from 'reactn'
import { InputGroup, FormControl, Button, Form } from 'react-bootstrap'
import ClientApi from '../../Controllers/ClientApi'
import swal from 'sweetalert'

export default class Detail extends Component {
  constructor (props) {
    super(props)
    this.state = {
      _id: this.props._id,
      code: this.props.code,
      name: this.props.name,
      type: this.props.type,
      progressive: this.props.progressive,
      room: this.props.room,
      enabled: this.props.enabled
    }
  }

  async update (id) {
    const updated = {
      name: this.state.name,
      // type: this.state.type,   -> now it's ever 'card'
      progressive: this.state.progressive,
      room: this.state.room,
      enabled: this.state.enabled
    }

    if (updated.name !== '') {
      const res = await ClientApi.patch('/access', id, updated)
      res.ok
        ? swal({
          title: 'Successo',
          text: 'Il prodotto è stato aggiornato correttamente',
          icon: 'info',
          buttons: false,
          timer: 2000
        })
        : swal({
          title: 'Errore',
          text: 'Impossibile aggiornare il prodotto, contattare l\'assistenza',
          icon: 'error',
          buttons: false,
          timer: 2000
        })
    } else {
      swal({
        title: 'Attenzione',
        text: 'Completare tutti i campi (eccetto note) prima di salvare',
        icon: 'warning',
        buttons: false,
        timer: 2000
      })
    }
  }

  async delete () {
    const ret = await ClientApi.delete('/access', this.state._id)
    ret
      ? swal({
        title: 'Successo',
        text: 'Il permesso di accesso è stato eliminato correttamente',
        icon: 'success',
        buttons: false,
        timer: 2000
      })
      : swal({
        title: 'Errore',
        text: 'Impossibile eliminare il permesso di accesso, contattare l\'assistenza',
        icon: 'error',
        buttons: false,
        timer: 2000
      })
  }

  render () {
    const { container, title, save } = styles
    const { _id, code, name, type, progressive, room, enabled } = this.state

    return (
      <div style={container}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <InputGroup className='mb-3' style={{ margin: 10 }}>
            <InputGroup.Prepend>
              <InputGroup.Text style={title}>Codice</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              value={code}
              disabled
            />
          </InputGroup>
          <InputGroup className='mb-3' style={{ margin: 10 }}>
            <InputGroup.Prepend>
              <InputGroup.Text style={title}>Nome Cognome / Rag. Sociale</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              value={name}
              onChange={(el) => this.setState({ name: el.target.value })}
            />
          </InputGroup>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <InputGroup className='mb-3' style={{ minWidth: '30%', margin: 10 }}>
            <InputGroup.Prepend>
              <InputGroup.Text style={title}>Categoria</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              value={type}
              disabled
            />
          </InputGroup>
          <InputGroup className='mb-3' style={{ margin: 10 }}>
            <InputGroup.Prepend>
              <InputGroup.Text style={title}>Progressivo</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              value={progressive}
              onChange={(el) => this.setState({ progressive: el.target.value })}
            />
          </InputGroup>
          <InputGroup className='mb-3' style={{ margin: 10 }}>
            <InputGroup.Prepend>
              <InputGroup.Text style={title}>Camera</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              value={room}
              onChange={(el) => this.setState({ room: el.target.value })}
            />
          </InputGroup>
          <InputGroup className='mb-3' style={{ margin: 10 }}>
            <Form.Check
              id={'enabled-switch-detail-' + _id}
              style={{ marginTop: 5 }}
              label={enabled ? 'Accesso abilitato' : 'Accesso disabilitato'}
              checked={enabled === 1}
              onChange={(e) => this.setState({ enabled: enabled ? 0 : 1 })}
            />
          </InputGroup>
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button style={save} size='lg' variant='outline-danger' id={_id} onClick={(el) => this.delete(el.target.id)}>Elimina</Button>
          <Button style={save} size='lg' variant='outline-warning' id={_id} onClick={(el) => this.update(el.target.id)}>Aggiorna</Button>
        </div>
      </div>
    )
  }
}

const styles = {
  container: {
    width: '100%'
  },
  title: {
    textTransform: 'uppercase',
    fontSize: 12,
    fontStyle: 'italic'
  },
  save: {
    margin: 10
  }
}
