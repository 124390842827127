import axios from 'axios'

const CameraApi = {}
CameraApi.API_URL = 'http://localhost:4000'
const API_URL = 'http://localhost:4000'

const getClient = () => axios.create({
  baseURL: API_URL,
  timeout: 6000
})

var client = getClient()

CameraApi.wait = ms => new Promise(resolve => setTimeout(resolve, ms))

CameraApi.get = async (url) => {
  try {
    const response = await client.get(url)
    response.ok = response.statusText === 'OK'
    return response
  } catch (err) {
    return { ok: false, error: err }
  }
}

CameraApi.post = async (url, data) => {
  try {
    const response = await client.post(url, data)
    return response
  } catch (err) {
    return { ok: false, error: err }
  }
}

export default CameraApi
