import React, { Component } from 'reactn'
import { Card, CardGroup } from 'react-bootstrap'
import { persistState, restoreState } from '../../Controllers/GlobalState'

export default class Settings extends Component {
  constructor (props) {
    super(props)
    this.state = {
      theme: 0
    }
  }

  async componentDidMount () {
    await restoreState()
    const { theme } = this.global
    this.setState({ theme })
  }

  render () {
    const { page, main, container, label } = styles
    const themeColor = this.state.theme === 0 ? 'rgba(30,30,30,0.9)' : 'white'
    const textColor = this.state.theme === 0 ? 'white' : 'rgba(30,30,30,0.9)'
    const shadowColor = this.state.theme === 0 ? '7px 7px 21px -1px rgba(255, 255, 255, 0.6)' : '7px 7px 21px -1px rgba(255, 250, 0, 1.0)'

    return (
      <div style={{ ...page, background: themeColor, color: textColor }}>
        <CardGroup
          style={{
            ...main,
            background: themeColor,
            WebkitBoxShadow: shadowColor,
            MozBoxShadow: shadowColor,
            boxShadow: shadowColor
          }}
          onClick={() => {
            this.setState({ theme: this.state.theme === 0 ? 1 : 0 })
            this.setGlobal({ theme: this.state.theme === 0 ? 1 : 0 }, persistState)
          }}
        >
          <Card style={container}>
            <p style={label}>{this.state.theme === 0 ? 'Tema Dark' : 'Tema Light'}</p>
          </Card>
        </CardGroup>
      </div>
    )
  }
}

const styles = {
  page: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  main: {
    minWidth: 300,
    minHeight: 300,
    borderRadius: 10,
    margin: 20,
    padding: 0
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    padding: 20,
    margin: 5
  },
  label: {
    fontSize: 20
  }
}
