import React, { Component } from 'reactn'
import Telecamera from './Telecamera'
import CameraApi from '../../Controllers/CameraApi'
import swal from 'sweetalert'

export default class Videosorveglianza extends Component {
  constructor (props) {
    super(props)
    this.state = {
      player: null,
      connected: false,
      camlist: [],
      center: {
        lat: 59.95,
        lng: 30.33
      },
      zoom: 11
    }
  }

  async componentDidMount () {
    // const res = await CameraApi.get('/start')
    // if (res.data && res.data.start) {
    //   swal({
    //     title: 'Info',
    //     text: 'Connessione in corso...',
    //     icon: 'info',
    //     buttons: false,
    //     timer: 2000
    //   })
    //   this.setState({ connected: true })
    // } else {
    //   swal({
    //     title: 'Attenzione',
    //     text: 'Connessione fallita.',
    //     icon: 'warning',
    //     buttons: false,
    //     timer: 2000
    //   }).then(() => this.props.history.push('/'))
    // }
  }

  async stopStreamFromWs () {
    // const res = await CameraApi.get('/stop')
    // if (res.data && res.data.stop) {
    //   swal({
    //     title: 'Info',
    //     text: 'Disconnessione in corso...',
    //     icon: 'info',
    //     buttons: false,
    //     timer: 2000
    //   })
    // }
    // this.props.history.push('/')
  }

  render () {
    const { page, contenuto } = styles
    const { connected, camlist } = this.state

    return (
      <div style={page}>
        {connected && camlist.map((e, i) => {
          return <div style={e.icon} key={i}><Telecamera id={e.id} url={e.url} description={e.description} /></div>
        })}
        <div style={{ height: '100vh', width: '100%' }}>
        ciao
        </div>
      </div>
    )
  }
}

const styles = {
  badgeStyle: {
    fontSize: 30,
    color: 'rgba(234, 152, 78, 1.0)',
    borderBottom: '3px solid rgba(234, 152, 78, 1.0)'
  },
  contenuto: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  page: {
    maxWidth: '90%',
    maxHeight: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    margin: 'auto'
  },
  centrale: {
    maxWidth: '85%',
    margin: 'auto'
  }
}
