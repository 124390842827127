import React, { Component } from 'reactn'
import { restoreState } from '../../Controllers/GlobalState'
import Detail from './Detail'
import { NewCard } from './Modals'
import { Card, Button, Modal, Breadcrumb, InputGroup } from 'react-bootstrap'
import BootstrapTable from 'react-bootstrap-table-next'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import filterFactory from 'react-bootstrap-table2-filter'
import paginationFactory from 'react-bootstrap-table2-paginator'
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css'
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css'
import ClientApi from '../../Controllers/ClientApi'
import io from 'socket.io-client'
import feathers from '@feathersjs/feathers'
import socketio from '@feathersjs/socketio-client'
import { CSVLink } from 'react-csv'
import moment from 'moment'

const renderCell = (cell) => { return <p style={styles.cell}>{cell}</p> }
const green = require('../../Assets/Icons/green.png')
const red = require('../../Assets/Icons/red.png')
const renderEnabled = (cell) => {
  return (
    cell === 1
      ? <img src={green} alt='' style={{ width: 32, height: 32, marginTop: 5 }} />
      : <img src={red} alt='' style={{ width: 32, height: 32, marginTop: 5 }} />
  )
}
const renderTitle = (cell) => { return <p style={styles.cell}><b>{cell}</b></p> }

export default class Management extends Component {
  constructor (props) {
    super(props)
    this.state = {
      data: [],
      showNewCard: false,
      theme: 1,
      locked: true
    }
  }

  async refreshTableData () {
    const res = await ClientApi.get('/access?company=' + this.state.companyID)
    if (res && res.data !== undefined && res.data.length > 0) {
      this.setState({ data: res.data, locked: !this.state.locked })
    }
  }

  async componentDidMount () {
    await restoreState()
    const { theme, companyID } = this.global
    this.setState({ theme, companyID })

    await this.refreshTableData()

    const client = feathers()
    client.configure(socketio(io(ClientApi.getUrl())))
    client.service('access').on('created', async response => {
      response._id !== undefined && await this.refreshTableData()
    })

    client.service('access').on('removed', async response => {
      response._id !== undefined && await this.refreshTableData()
    })

    client.service('access').on('patched', async response => {
      response._id !== undefined && await this.refreshTableData()
    })
  }

  render () {
    const {
      page, mainkit, toolkit, lateral, table,
      internalLateral, funcbtn, lateralSmall,
      main, container, iconStyle
    } = styles
    const card = require('../../Assets/Icons/card.png')
    const themeColor = this.state.theme === 0 ? 'rgba(30,30,30,0.9)' : 'white'
    const textColor = this.state.theme === 0 ? 'white' : 'rgba(30,30,30,0.9)'
    const shadowColor = this.state.theme === 0 ? '7px 7px 21px -1px rgba(255, 255, 255, 0.5)' : '7px 7px 21px -1px rgba(30, 30, 30, 0.4)'
    const { SearchBar, ClearSearchButton } = Search
    const defaultSorted = [{ dataField: 'name', order: 'asc' }]
    const { data } = this.state

    const expandRow = {
      onlyOneExpanding: true,
      showExpandColumn: false,
      renderer: row => (
        <Detail
          _id={row._id}
          code={row.code}
          name={row.name}
          type={row.type}
          progressive={row.progressive}
          room={row.room}
          enabled={row.enabled}
        />
      )
    }

    const rowBackground = (row, rowIndex) => {
      const style = {}
      rowIndex % 2 === 0 ? style.backgroundColor = '#cfdede' : style.backgroundColor = '#f9f9f9'
      return style
    }

    const options = {
      paginationSize: 4,
      pageStartIndex: 0,
      alwaysShowAllBtns: true,
      withFirstAndLast: true,
      firstPageText: 'Inzio',
      prePageText: 'Indietro',
      nextPageText: 'Avanti',
      lastPageText: 'Fine',
      showTotal: true,
      paginationTotalRenderer: (from, to, size) => { return (<i> Records da <b>{from}</b> a <b>{to} .</b> Risultati totali: <b>{size}</b></i>) },
      sizePerPageList: [{ text: '12', value: 12 }]
    }

    return (
      <div style={{ ...page, background: themeColor }}>
        <div style={lateral}>
          <Card
            style={{
              ...main,
              backgroundColor: 'rgba(212, 82, 84, 1.0)',
              WebkitBoxShadow: '7px 7px 21px -1px rgba(212, 82, 84, 1.0)',
              MozBoxShadow: '7px 7px 21px -1px rgba(212, 82, 84, 1.0)',
              boxShadow: '7px 7px 21px -1px rgba(212, 82, 84, 1.0)'
            }}
            onClick={() => this.props.history.push('/cards')}
          >
            <Card style={{ ...container, background: themeColor }}>
              <img src={card} alt='void' style={iconStyle} />
              <p style={{ paddingTop: 10, color: textColor }}>Controllo accessi</p>
            </Card>
          </Card>
          <Card style={{
            ...lateralSmall,
            marginTop: 0,
            WebkitBoxShadow: shadowColor,
            MozBoxShadow: shadowColor,
            boxShadow: shadowColor
          }}
          >
            <Card.Header style={{ color: 'rgb(106, 117, 126)' }}><b>Funzioni</b></Card.Header>
            <div style={internalLateral}>
              <Button variant='outline-primary' style={funcbtn} onClick={() => this.setState({ showNewCard: true })}>Nuova card</Button>
            </div>
            <Modal show={this.state.showNewCard} onHide={() => this.setState({ showNewCard: false })}>
              <Modal.Header closeButton>
                <Modal.Title>Inserire i dati della nuova card</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <NewCard close={() => this.setState({ showNewCard: false })} companyID={this.state.companyID} />
              </Modal.Body>
            </Modal>
          </Card>
          {!this.state.locked &&
            <Card style={{
              ...lateralSmall,
              marginTop: 20,
              WebkitBoxShadow: shadowColor,
              MozBoxShadow: shadowColor,
              boxShadow: shadowColor
            }}
            >
              <Card.Header style={{ color: 'rgb(106, 117, 126)' }}><b>Strumenti</b></Card.Header>
              <div style={internalLateral}>
                <CSVLink
                  style={funcbtn}
                  filename={'Cards-' + moment().unix() + '.csv'} className='btn btn-outline-danger'
                  data={this.state.data.map((el, i) => {
                    return {
                      ID: i,
                      Codice: el.code,
                      NomeCognomeRagSoc: el.name,
                      Tipo: el.type,
                      Progressive: el.progressive,
                      Camera: el.room,
                      Abilitato: el.enabled === 1 ? 'SI' : 'NO'
                    }
                  })}
                >Download CSV
                </CSVLink>
              </div>
            </Card>}
        </div>
        <div style={table}>
          <ToolkitProvider
            keyField='code' data={data} columns={fields} loading striped hover search
            exportCSV={{
              fileName: 'Cards-' + moment().unix() + '.csv',
              onlyExportFiltered: false
            }}
          >
            {props =>
              <div style={{ padding: 10 }}>
                <Card style={{
                  ...mainkit,
                  WebkitBoxShadow: shadowColor,
                  MozBoxShadow: shadowColor,
                  boxShadow: shadowColor
                }}
                >
                  <div style={toolkit}>
                    <Breadcrumb style={{ minWidth: '180px', margin: 10, height: 40 }}>
                      <Breadcrumb.Item href='#' onClick={() => this.props.history.push('/home')}>Home</Breadcrumb.Item>
                      <Breadcrumb.Item active>Cards</Breadcrumb.Item>
                    </Breadcrumb>
                    <Button
                      variant='success' style={{ minWidth: 120, height: 40, margin: 10 }}
                      onClick={() => this.props.history.push('/home')}
                    >Indietro
                    </Button>
                    <InputGroup style={{ margin: 10, height: 40, display: 'flex', flexDirection: 'row', alignItems: 'stretch' }}>
                      <InputGroup.Prepend style={{ width: '100px' }}>
                        <ClearSearchButton {...props.searchProps} text='Reset' className='reset-btn' />
                      </InputGroup.Prepend>
                      <SearchBar
                        {...props.searchProps} className='search-item'
                        placeholder='Inizia a digitare per filtrate le card...'
                        delay={50} style={{ height: 40, fontStyle: 'italic' }}
                      />
                    </InputGroup>
                  </div>
                  <BootstrapTable
                    {...props.baseProps}
                    noDataIndication='Nessun record trovato'
                    filter={filterFactory()}
                    pagination={paginationFactory(options)}
                    expandRow={expandRow}
                    tabIndexCell
                    bootstrap4
                    defaultSorted={defaultSorted}
                    rowStyle={rowBackground}
                  />
                </Card>
              </div>}
          </ToolkitProvider>
        </div>
      </div>
    )
  }
}

const styles = {
  page: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    flexDirection: 'row'
  },
  lateral: {
    width: '14%',
    margin: 10
  },
  lateralSmall: {
    margin: 5,
    borderRadius: 10
  },
  internalLateral: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'stretch'
  },
  funcbtn: {
    margin: 8
  },
  table: {
    width: '84%'
  },
  column: {
    background: 'rgb(0, 118, 197)',
    color: 'white',
    fontSize: 16,
    textTransform: 'uppercase',
    height: 10
  },
  row: {
    color: 'white',
    height: 10
  },
  cell: {
    fontSize: 15,
    wordWrap: 'break-word'
  },
  mainkit: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 10,
    padding: 10
  },
  toolkit: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 20
  },
  exportBtn: {
    width: 100,
    height: 40,
    backgroundColor: 'red',
    color: 'white',
    marginRight: 10,
    marginTop: 5
  },
  smallButton: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  main: {
    maxWidth: '100%',
    borderRadius: 10,
    margin: 5,
    marginBottom: 20
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    padding: 5,
    margin: 5,
    color: 'white'
  },
  iconStyle: {
    width: '70%',
    marginTop: 10
  }
}

const fields = [
  {
    dataField: 'code',
    text: 'Codice',
    headerStyle: { ...styles.column, width: '20%' },
    sort: true,
    formatter: renderTitle,
    align: 'left'
  },
  {
    dataField: 'name',
    text: 'Nome Cognome / Rag. Sociale',
    headerStyle: { ...styles.column, width: '40%' },
    sort: true,
    formatter: renderCell,
    align: 'left',
    events: {
    }
  },
  {
    dataField: 'type',
    text: 'Tipo',
    headerStyle: { ...styles.column, width: '10%' },
    sort: true,
    formatter: renderCell,
    align: 'right'
  },
  {
    dataField: 'progressive',
    text: 'Progressivo',
    headerStyle: { ...styles.column, width: '10%' },
    sort: true,
    formatter: renderCell,
    align: 'right'
  },
  {
    dataField: 'room',
    text: 'Camera',
    headerStyle: { ...styles.column, width: '10%' },
    sort: true,
    formatter: renderCell,
    align: 'right'
  },
  {
    dataField: 'enabled',
    text: 'Abilitato',
    headerStyle: { ...styles.column, width: '10%' },
    sort: true,
    formatter: renderEnabled,
    align: 'center'
  }
]
