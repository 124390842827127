import React, { Component } from 'reactn'
import { Card, CardGroup, Button } from 'react-bootstrap'
import { restoreState, clearState, persistState } from '../Controllers/GlobalState'
import ClientApi from '../Controllers/ClientApi'

export default class Home extends Component {
  constructor (props) {
    super(props)
    this.state = {
      theme: 1,
      email: ''
    }
  }

  async componentDidMount () {
    await restoreState()
    const { theme, email } = this.global
    this.setState({ theme, email })
  }

  render () {
    const { full, page, main, container, label, logostyle, logoContainer, iconStyle, logout } = styles
    const card = require('../Assets/Icons/card.png')
    const camera = require('../Assets/Icons/camera.png')
    const network = require('../Assets/Icons/network.png')
    // const ronda = require('../Assets/Icons/ronda.png')
    const settings = require('../Assets/Icons/settings.png')
    const logo = this.state.theme === 0 ? require('../Assets/logowhite.png') : require('../Assets/logo.png')
    const themeColor = this.state.theme === 0 ? 'rgba(30,30,30,0.9)' : 'white'
    const textColor = this.state.theme === 0 ? 'white' : 'rgba(30,30,30,0.9)'

    const exit = async () => {
      this.props.history.push('/')
      await clearState()
    }

    return (
      <div style={{ ...full, background: themeColor, color: textColor }}>
        <div style={logoContainer}>
          <img src={logo} style={logostyle} alt='void' />
          <div style={logout}>
            <p style={{ color: textColor, margin: 10 }}><b>{this.state.email}</b></p>
            <Button variant='outline-danger' onClick={() => exit()}>Logout</Button>
          </div>
        </div>
        <div style={page}>
          <CardGroup
            style={{
              ...main,
              backgroundColor: 'rgba(212, 82, 84, 1.0)',
              WebkitBoxShadow: '7px 7px 21px -1px rgba(212, 82, 84, 1.0)',
              MozBoxShadow: '7px 7px 21px -1px rgba(212, 82, 84, 1.0)',
              boxShadow: '7px 7px 21px -1px rgba(212, 82, 84, 1.0)'
            }}
            onClick={() => this.props.history.push('/cards')}
          >
            <Card style={{ ...container, background: themeColor }}>
              <img src={card} alt='void' style={iconStyle} />
              <p style={label}>Controllo accessi</p>
            </Card>
          </CardGroup>
          {/* <CardGroup
            style={{
              ...main,
              backgroundColor: 'rgba(105, 179, 217, 1.0)',
              WebkitBoxShadow: '7px 7px 21px -1px rgba(105, 179, 217, 1.0)',
              MozBoxShadow: '7px 7px 21px -1px rgba(105, 179, 217, 1.0)',
              boxShadow: '7px 7px 21px -1px rgba(105, 179, 217, 1.0)'
            }}
            onClick={() => this.props.history.push('/home')}
          >
            <Card style={{ ...container, background: themeColor }}>
              <img src={camera} alt='void' style={iconStyle} />
              <p style={label}>Videosorveglianza</p>
            </Card>
          </CardGroup>
          <CardGroup
            style={{
              ...main,
              backgroundColor: 'rgba(234, 152, 78, 1.0)',
              WebkitBoxShadow: '7px 7px 21px -1px rgba(234, 152, 78, 1.0)',
              MozBoxShadow: '7px 7px 21px -1px rgba(234, 152, 78, 1.0)',
              boxShadow: '7px 7px 21px -1px rgba(234, 152, 78, 1.0)'
            }}
            onClick={() => this.props.history.push('/home')}
          >
            <Card style={{ ...container, background: themeColor }}>
              <img src={network} alt='void' style={iconStyle} />
              <p style={label}>Network Tester</p>
            </Card>
          </CardGroup> */}
          {/* <CardGroup
            style={{
              ...main,
              backgroundColor: 'rgba(79, 190, 167, 1.0)',
              WebkitBoxShadow: '7px 7px 21px -1px rgba(79, 190, 167, 1.0)',
              MozBoxShadow: '7px 7px 21px -1px rgba(79, 190, 167, 1.0)',
              boxShadow: '7px 7px 21px -1px rgba(79, 190, 167, 1.0)'
            }}
            onClick={() => this.props.history.push('/')}
          >
            <Card style={container}>
              <img src={ronda} alt='void' style={iconStyle} />
              <p style={label}>Manager Ronda</p>
            </Card>
          </CardGroup> */}
          {/* <CardGroup
            style={{
              ...main,
              backgroundColor: 'rgba(79, 190, 167, 1.0)',
              WebkitBoxShadow: '7px 7px 21px -1px rgba(79, 190, 167, 1.0)',
              MozBoxShadow: '7px 7px 21px -1px rgba(79, 190, 167, 1.0)',
              boxShadow: '7px 7px 21px -1px rgba(79, 190, 167, 1.0)'
            }}
            onClick={() => this.props.history.push('/settings')}
          >
            <Card style={{ ...container, background: themeColor }}>
              <img src={settings} alt='void' style={iconStyle} />
              <p style={label}>Impostazioni</p>
            </Card>
          </CardGroup> */}
        </div>
      </div>
    )
  }
}

const styles = {
  full: {
    width: '100%',
    height: '100%'
  },
  page: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  main: {
    maxWidth: '20%',
    borderRadius: 10,
    margin: 20,
    padding: 0
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    padding: 20,
    margin: 5
  },
  iconStyle: {
    width: '80%',
    margin: 30
  },
  logoContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    padding: 5
  },
  logostyle: {
    width: 400,
    margin: 20,
    borderRadius: 10,
    padding: 10,
    // WebkitFilter: 'drop-shadow(0px 0px 50px rgba(15, 85, 170, 1.0))',
    // filter: 'drop-shadow(8px 8px 20px rgba(15, 85, 170, 1.0))'
    WebkitFilter: 'drop-shadow(0px 0px 60px rgba(255, 255, 255, 1.0))',
    filter: 'drop-shadow(8px 8px 60px rgba(255, 255, 255, 1.0))'
  },
  icona: {
    display: 'flex',
    flex: 'auto',
    maxWidth: '100%',
    padding: 20
  },
  button: {
    width: 256,
    margin: 20
  },
  label: {
    fontSize: 20
  },
  logout: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    margin: 15
  }
}
